import React from "react";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import PropertyList from "./components/property-list";
import PropertySearch from "./components/property-search";
import Properties from "../../common/Properties";
import Paginator from "../../common/Paginator";
import { templateVariableValue } from "../../helpers/templateVariables";
import { translateStatic } from "../../helpers/translationHelper";

import * as theme1Styles from "../theme1/scss/theme1.module.scss";

class Theme1Properties extends Properties {
  constructor(props) {
    super(props);
  }

  renderProperties() {
    const { 
      result, 
      location 
    } = this.props;

    const {
      currentLocaleKey: locale, 
      defaultLocaleKey: defaultLocale
    } = result;

    return (
      <div className={`${theme1Styles.mainContent}`}>
        <div className={theme1Styles.wrapper}>
          <div className={`${theme1Styles.propHeader}`}>
            <div className={`${theme1Styles.propHeaderLeft}`}>
              <h2 className={`${theme1Styles.pageTitle}`}>
                {translateStatic("properties", locale, defaultLocale)}
              </h2>
            </div>
            <div className={`${theme1Styles.propHeaderRight}`}>
              <select
                className={`${theme1Styles.field}`}
                value={this.state.sort}
                onChange={this.handleSortChange}
              >
                <option value="">Sort</option>
                {Object.keys(this.state.sortOptions).map((key) => {
                  return (
                    <option value={key} key={key}>
                      {this.state.sortOptions[key]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <PropertyList
            properties={this.state.properties}
            locale={locale}
            defaultLocale={defaultLocale}
          />
          <Paginator
            themeStyles={theme1Styles}
            paginator={this.state.paginatorInfo}
            prefix={this.state.navigationPrefix}
            search={location.search}
          />
        </div>
        {(() => {
          if (this.state.paginatorInfo.total == 0) {
            return (
              <div className={`${theme1Styles.noDataMsg}`}>
                <span>
                  {translateStatic("no_property_found", locale, defaultLocale)}
                </span>
              </div>
            );
          }
        })()}
      </div>
    );
  }

  render() {
    const { 
      result, 
      pageContext, 
      location
    } = this.props;

    const {
      allProperties, 
      propertyCategories, 
      propertyTypes
    } = pageContext;

    const {
      remarso: {
        domainByURL: {
          website: {
            templateConfiguration,
            template: { templateVariables },
          },
        },
      },
      currentLocaleKey: locale, 
      defaultLocaleKey: defaultLocale
    } = result;

    const metaTitle = templateVariableValue(
      templateConfiguration,
      templateVariables,
      "meta-title-properties",
      locale,
      defaultLocale
    );

    return (
      <Layout>
        <SEO metaTitle={metaTitle} />

        <div className={`${theme1Styles.listingHeaderC}`}>
          <div className={theme1Styles.wrapper}>
            <div className={`${theme1Styles.listingHeader}`}>
              <PropertySearch
                properties={allProperties}
                setProperties={this.setProperties}
                propertyCategories={propertyCategories}
                propertyTypes={propertyTypes}
                locale={locale}
                defaultLocale={defaultLocale}
                navigationPrefix={this.state.navigationPrefix}
                location={location}
              />
            </div>
          </div>
        </div>

        {this.renderProperties()}
      </Layout>
    );
  }
}

export default Theme1Properties;

Theme1Properties.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
  location: PropTypes.object,
};
