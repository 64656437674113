import React from "react";
import PropTypes from "prop-types";

import WithThemeLoading from "../hoc/withThemeLoading";
import Theme1Properties from "../themes/theme1/theme1Properties";
import Theme2Properties from "../themes/theme2/theme2Properties";
import Theme3Properties from "../themes/theme3/theme3Properties";
import Theme4Properties from "../themes/theme4/theme4Properties";
import Theme5Properties from "../themes/theme5/theme5Properties";

const Properties = (props) => {
  /** Begin: Theme Switcher */
  const templateId =
    process.env.GATSBY_TEST_THEME === "0"
      ? props.pageContext.template.id
      : process.env.GATSBY_TEST_THEME;
  const themes = [
    Theme1Properties,
    Theme2Properties,
    Theme3Properties,
    Theme4Properties,
    Theme5Properties,
  ];
  const ThemeLoading =
    parseInt(templateId) > 0
      ? WithThemeLoading(themes[parseInt(templateId) - 1])
      : WithThemeLoading(Theme1Properties);
  /** End: Theme Switcher */

  return <ThemeLoading {...props} />;
};

Properties.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Properties;
